<template>
  <router-link
    class="small-author"
    :class="{'small-author__disable': !author?.active}"
    v-if="author"
    :to="{name: toAuthor, params: { id: author.id, back: toBack}}"
  >
    <div class="small-author__picture">
      <div class="small-author__plug" v-if="!author.avatarCurrent">
        <svg viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M31.8423 28.2997L22.5668 23.4655L26.1349 18.8408C26.791 18.0024 27.1804 16.9783 27.1804 15.8795V8.90569C27.1804 4.50534 22.8559 0.938477 17.5208 0.938477C12.1857 0.938477 7.86115 4.50534 7.86115 8.90569V15.8795C7.86115 16.9783 8.2505 18.0024 8.92222 18.8623L8.90662 18.8402L12.4747 23.4649L3.19926 28.2991C1.52443 29.1822 0.430664 30.7077 0.430664 32.4439V37.7531L34.6109 37.7096V32.4439C34.6109 30.7077 33.5164 29.1822 31.8661 28.3108L31.8423 28.2991V28.2997Z"/>
        </svg>
      </div>
      <img
        :src="author.avatarCurrent"
        :alt="author.fullName"
        v-else
      >
    </div>
    <div class="small-author__content" v-if="author.fullName">
      <div class="small-author__label">{{ labelAuthor }}</div>
      <h6 class="small-author__name">{{ author.fullName }}</h6>
    </div>
  </router-link>
</template>

<script>
import Author from '@/assets/js/Author.class';

export default {
  name: 'SmallAuthor',
  props: {
    author: {
      type: Author,
      required: true,
    },
    toAuthor: {
      type: String,
      default: 'item-author',
    },
    back: {
      default: null
    },
    isCurator: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    toBack() {
      return this.back || this.$route
    },
    labelAuthor() {
      return this.isCurator ? this.$t('lk.curator.header') : this.$t('lk.authors.smallAuthorLabel')
    }
  },
}
</script>

<style lang="scss" scoped>
.small-author {
  pointer-events: auto;
  display: flex;
  width: fit-content;
  position: relative;
  z-index: 2;
  cursor: pointer;
  text-decoration: none;
  &__disable{
    opacity: 0.3;
  }
  &__picture {
    flex: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      z-index: 2;
    }
  }
  &._big &__picture {
    width: 2.875rem;
    height: 2.875rem;
    @include media-breakpoint-up(md) {
      width: 3.5rem;
      height: 3.5rem;
    }
    @include media-breakpoint-up(xl) {
      width: 5.375rem;
      height: 5.375rem;
    }
  }
  &__plug {
    width: 100%;
    height: 100%;
    border: 0.0625rem solid var(--color);
    border-radius: 50%;
    position: relative;
    z-index: 1;
    svg {
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      path {
        fill: var(--color);
      }
    }
  }
  &._big &__plug {
    svg {
      @include media-breakpoint-up(md) {
        width: 1.5rem;
        height: 1.5rem;
      }
      @include media-breakpoint-up(xl) {
        width: 2rem;
        height: 2rem;
      }
    }
  }
  &__content {
    flex: 1 1 auto;
    width: 100%;
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    ._is-carousel & {
      padding-top: 0.1875rem;
      padding-bottom: 0.1875rem;
      @include media-breakpoint-up(md) {
        padding-top: 0.125rem;
        padding-bottom: 0.125rem;
      }
    }
  }
  &._big &__content {
    padding-top: 0;
    padding-bottom: 0;
  }
  &__picture + &__content {
    padding-left: 0.5rem;
  }
  &._big &__picture + &__content {
    padding-left: 1rem;
    @include media-breakpoint-up(xl) {
      padding-left: 1.25rem;
    }
  }
  &__label {
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 120%;
    color: var(--dark-contrast);
    opacity: 0.5;
    ._is-carousel & {
      font-size: 0.75rem;
      @include media-breakpoint-up(md) {
        font-size: 0.8125rem;
      }
    }
  }
  &._big &__label {
    line-height: 140%;
    font-size: 0.875rem;
    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
    }
  }
  &__name {
    font-weight: 500;
    line-height: 120%;
    font-size: 0.875rem;
    color: var(--dark-contrast);
    margin: 0;
    ._is-carousel & {
      font-size: 0.8125rem;
      @include media-breakpoint-up(md) {
        font-size: 0.875rem;
      }
    }
  }
  &._big &__name {
    line-height: 140%;
    font-size: 0.9375rem;
    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }
    @include media-breakpoint-up(xl) {
      font-size: 1.5rem;
    }
  }
  &:hover &__name {
    color: #20A8D8;
    text-decoration: underline;
  }
  &__label + &__name {
    margin-top: 0.25rem;
  }
  &._big &__label + &__name {
    margin-top: 0;
    @include media-breakpoint-up(md) {
      margin-top: 0.125rem;
    }
  }
}
.training-card__content .small-author__name,
.training-card__content .small-author__label,
.training-detail__picture-content .small-author__name,
.training-detail__picture-content .small-author__label{
  color: #fff;
}
.training-card__content .small-author__plug,
.training-detail__picture-content .small-author__plug{
  border-color: #fff !important;
  /deep/ svg path{
    fill: #fff !important;
  }
}
</style>
